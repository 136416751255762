import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { logger } from "../../utilities/logger"
import AppConfig from '../../configs/AppConfig';
import LoadingImage from '../utility/LoadingImage';
import {gameActions } from '../../store/index';
import { useDispatch } from "react-redux";


function ProductInfoGroup(props) {
    
    let [gameStateLoaded, setGameStateLoaded] =   useState(false);
    let [gameImage, setGameImage] = useState("");
    let [gameDetails, setGameDetails] = useState([]);
    let dispatch = useDispatch();

    const gameReduxState = useSelector((state) => state.game);  

    
    
    let gameDetailFirst = {image : ""};
    
    
    

    useEffect(()=>{ 
      if(gameReduxState.gameDetail !== null){
        gameDetailFirst = gameReduxState.gameDetail[0];
        setGameDetails(gameReduxState.gameDetail);

        if(gameDetailFirst){
         
            setGameStateLoaded(true);  
            setGameImage(gameDetailFirst.image);
            
            
        }
        
    }          
    },[gameReduxState]);        

    
    let changeDisplayItems = (productId) => {
      let selectedProduct = gameDetails.map((product, index) => {
        if(product.id === productId){
          return product;
        }
      });
      dispatch(gameActions.setDisplayItems(selectedProduct[0].game_items));

    }
    

    

    // logger.debug(gameStateLoaded);

    let gameProducts = (products) => {
      return products.map((product, index) => {
        return <div key={index} className="gameType btn" onClick={()=>{changeDisplayItems(product.id)}}>{product.name}</div>
      });
    }
    

   
   
    

  return (
    <>
        <div
              className="col-lg-6 col-md-12 col-xs-12 col-sm-12 mt-2"
              style={{ "minHeight": "50vh", border: "none" }}
            >
              <div className="gameImageBlk" style={{  }}>
                <img
                  src={ gameStateLoaded  ? gameImage : AppConfig().loadingImage }
                  // src="abc"
                  alt="game detail"
                  style={{ width: "60%", height: "height: auto" }}
                />
              </div>

              <div className="d-flex justify-content-between p-2 mt-4">
                <h4 style={{ color: "#FF8503" }}>{props.gameTitle}</h4>
                <p
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "700",
                    fontSize: "14px",
                  }}
                >
                  How to buy
                </p>
              </div>

              <div className="gameTypeContainer" style={{}}>
                { gameStateLoaded ? gameProducts(gameDetails) : <LoadingImage widthPercentage="20%"/>}

                
              </div>
            </div>        
    </>
  )
}

ProductInfoGroup.propTypes = {}

export default ProductInfoGroup
