import {useState, useEffect} from 'react'
import CatPresenter   from '../presenters/CatPresenter';
import { logger } from '../utilities/logger';

const useCatFactFetch = props => {
    let [sampleResp, setSampleResp] = useState({fact : null});
    let [textLoading, setTextLoading]  = useState(false);
    
    let { fetchRandomCatFact } = CatPresenter();

   
    
    

    useEffect(()=> {
      

      let response;      

      let fetchData = async () => {
        setTextLoading(true);
        

        response = await fetchRandomCatFact();

        setSampleResp(response.data);

        
        setTextLoading(false);
        logger.debug(response);
        
      }

      
      
      
      fetchData();

      

    },[]);

    return [sampleResp, textLoading];
}



export default useCatFactFetch