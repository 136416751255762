import { getRandomCatFact} from '../dataAccess/apis/CatApis';

const CatPresenter = () => {
    
    


    let fetchRandomCatFact  =  async () => {
        let data = null;
        let status = "unknown";
        
        try {
            let response = await getRandomCatFact(); 
            status = "success"
            data = response.data;
            
        } catch (error) {
            status = "error"
        }

        return {status, data};
        

       
    };
  
    return {
        fetchRandomCatFact
    }
}

export default CatPresenter