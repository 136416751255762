import React from "react";
import { useEffect, useState, useRef } from "react";
import { navActiveActions } from "../store/index";
import { useDispatch } from "react-redux";
import HeaderOne from "../components/header/HeaderOne";
import "./css/gameList.css";
import ShoppingItem from "../components/shop/ShoppingItem";
import Footer from "../components/Footer";
import LoadingImage from "../components/utility/LoadingImage";
import { useSelector } from "react-redux";
import { logger } from "../utilities/logger";
import GamePresenter from '../presenters/GamePresenter';


const GameLisPage = () => {
  let dispatch = useDispatch();
  let { fetchAllGameGroup } = GamePresenter();
  const gameReduxState = useSelector((state) => state.game);  
  let [gamesLoaded, setGamesLoaded]  = useState(false);
  let [allGameGroups, setAllGameGroups] = useState([]); 
  let [searchKeyword, setSearchKeyword] = useState("");
  let searchKeywordRef = useRef(null);






  useEffect(() => {
    dispatch(navActiveActions.setActivePage("game_list"));
  }, []);

  useEffect(()=> {
         

    let fetchData = async () => {           
      await fetchAllGameGroup();            
    }

    fetchData();

  },[]);

  
  useEffect(()=> {
    if(gameReduxState.allGameGroup != null){
      logger.debug(gameReduxState.allGameGroup);
      if(gameReduxState.allGameGroup.length > 0){
        setAllGameGroups(gameReduxState.allGameGroup);
        setGamesLoaded(true);
      }
      
    }
  }, [gameReduxState]);

  logger.debug(gamesLoaded);

  let gameList = (allGameGroups) => {
    let mobileGameGroup = allGameGroups;
    return mobileGameGroup.map((value, index) => {
      
      return (
        
          <ShoppingItem
            key={index}
            imageUrl={value.image}
            gameCategoryId={value.game_product_category_id}
            title={value.game_product_category.name}
            
          />
          
        
      );
    });
  }

  let makeSearchProduct = () => {
    
    setGamesLoaded(false);
    setAllGameGroups(gameReduxState.allGameGroup);
    setTimeout(()=>{
      if(searchKeyword.trim().length !== 0){
        logger.debug(searchKeyword);
        
        if(allGameGroups.length > 0){
          // search the array with search keyword and show
          logger.debug(allGameGroups);
          let search = new RegExp(searchKeyword , 'i');
  
          let filteredProductGroups = allGameGroups.filter(item => search.test(item.game_product_category.name.toLowerCase()))
          logger.debug(filteredProductGroups);
          setAllGameGroups(filteredProductGroups);
    
        }
        else{
          // show no game to search message
    
        }
      }
      else{
        setAllGameGroups(gameReduxState.allGameGroup);
      }
    },350)
    
    setGamesLoaded(true);

    

  }

  let onchangeSearchKeyword = (e) => {
    

    setSearchKeyword(e.target.value);
    // logger.debug(searchKeyword);

    if(searchKeyword.trim().length === 0){
      setAllGameGroups(gameReduxState.allGameGroup);
      makeSearchProduct();
    }

  }

  let listAllProduct = () => {
    searchKeywordRef.current.value = "";
    setSearchKeyword("");
    setAllGameGroups(gameReduxState.allGameGroup);
    
  }

  let clearFilter = () => {
    if(searchKeyword.trim().length > 0){
      listAllProduct();
    }
    
  }

  useEffect(()=> {
    let timeOutId = setTimeout(()=>{
      
      if(searchKeyword.trim().length !== 0){
        
        if(allGameGroups.length > 0){
          // search the array with search keyword and show
          logger.debug(allGameGroups);
          let search = new RegExp(searchKeyword , 'i');
  
          let filteredProductGroups = allGameGroups.filter(item => search.test(item.game_product_category.name.toLowerCase()))
          logger.debug(filteredProductGroups);
          setAllGameGroups(filteredProductGroups);
    
        }
        else{
          // show no game to search message
    
        }
      }
      else{
        setAllGameGroups(gameReduxState.allGameGroup);
      }
    },400)

    return (() => {
      return clearTimeout(timeOutId);
    })
  }, [searchKeyword, gamesLoaded] )

  return (
    <>
      <HeaderOne />
      <main>
        <section className="container mt-4">
          <div className="row">
            <div
              className="col-xs-12  col-3"
              style={{ border: "none", minHeight: "200px" }}
            >
              <div
                className="container-fluid"
                style={{ border: "none", minHeight: "200px" }}
              >
                <div className="row">
                  <div className="col-12 d-flex mt-1">
                    <div>
                      <span className="h4"> Topup Type </span>
                    </div>
                    <div>
                      <div
                        style={{
                          backgroundColor: "#E88014",
                          width: "8px",
                          height: "24px",
                          marginTop: "4px",
                          marginLeft: "24px",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 topupTypeContainer">
                    <div className="topUpType" onClick={listAllProduct}> All Product</div>
                    <div className="topUpType"> Flash Top-up</div>
                    <div className="topUpType"> Voucher Purchase</div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-xs-12  col-9"
              style={{ border: "none", minHeight: "200px" }}
            >
              <div className="row">
                <form className="search" action="#" onSubmit={(e) => {e.preventDefault(); makeSearchProduct()}}>
                  <input
                    type="text"
                    placeholder="Search Games..."
                    name="search"
                    ref={searchKeywordRef}
                    onChange={onchangeSearchKeyword}
                  />
                  { searchKeyword.trim().length > 0 ?
                  <div className="clearBtn" onClick={clearFilter}>
                    <i class="fa fa-times-circle" style={{}}></i>
                  </div> : ""
                  }
                  <button type="submit"   >
                    <i className="fa fa-search"></i>                    
                  </button>
                </form>
              </div>
              <div className="row">
                <div
                  className="col-12 p-4 gameItemList"
                  style={{ border: "none" }}
                >
                  <div className="row justify-content-center mt-4">
                    { gamesLoaded ? gameList(allGameGroups) : <LoadingImage widthPercentage="50%" />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer/>
    </>
  );
};

export default GameLisPage;
