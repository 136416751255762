import React from 'react';
import { useEffect } from 'react';
import {useDispatch} from 'react-redux';
import {navActiveActions } from '../store/index';

function BuyHistoryPage() {

  let dispatch = useDispatch();

  useEffect(()=> {
    dispatch(navActiveActions.setActivePage('buy_history'));
    
  }, []);

  return (
    <h1>Buy History Page</h1>
  )
}

export default BuyHistoryPage