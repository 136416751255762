import { createSlice } from "@reduxjs/toolkit";

const gameSlice = createSlice({
    name : "game",
    initialState : { 
        appSource : "web_app",
        allGameGroup : [],
        gameDetail : {},
        displayItems : [],
        selectedItem : {
            id : 0,
            name : "",
            telecomPrice : 0,
            walletPrice : 0
        },
        isOrderFormValid : false,
        

    },
    reducers : {
        setAllGameGroup : (state, action) => {
            state.allGameGroup = action.payload;
        },
        setGameDetail : (state, action) => {
            state.gameDetail = action.payload;
        },
        setDisplayItems : (state, action) => {
            state.displayItems = action.payload;
        },
        setGameItemPrices : (state, action) => {
            state.selectedItem = action.payload;
        },        
        setOrderFormValid : (state, action) => {
            state.isOrderFormValid = action.payload;
        },
        
    }
});

export default gameSlice;