import AppConfig from "../configs/AppConfig";
import getLogger from 'loglevel-colored-level-prefix';

const logLevel = AppConfig().logLevel;
const options = {prefix: 'App Log ', level: logLevel};
const logger = getLogger(options);

export {logger};


