import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./shoppingItem.css";

function ShoppingItem({
  imageUrl,
  title,
  gameCategoryId
}) {
  let baseUrl = window.location.origin + "/";
  let gameDetailPathWithGameCategoryId = '/game-detail/'+gameCategoryId+'/'+title;
  let shapeImageUrl = baseUrl + "assets/img/shop/shop_wrap_img.png";

  return (
    <>
   
      <div className="col-lg-2 col-md-4 col-sm-4 col-4">
      <Link to={gameDetailPathWithGameCategoryId} >
        <div className="shop-item mb-60">
          <div className="shop-thumb">
            <img
              src={shapeImageUrl}
              alt=""
              className="shape"
            />
            
              <img
                src={imageUrl}
                alt="shopping item img"
                className="shop-img"
              />
           

            <div className="shop-date">
              <i className="fas fa-info-circle"></i>
              <span >detail</span>
            </div>
          </div>
          <div className="shop-content">
            <div className="shop-content-top">
              <div className="shop-title">
                <h5 className="title">
                  {title}
                  {/* <NavLink href="/game-detail">

                  </NavLink> */}
                </h5>
              </div>
            </div>
          </div>
        </div>
        </Link>
      </div>
      
    </>
  );
}

ShoppingItem.propTypes = {
  imageUrl : PropTypes.string,
  title : PropTypes.string.isRequired
};

export default ShoppingItem;
