import AppConfig from "../../configs/AppConfig";
import {apiCallGet, httpMethods} from "./generic/GenericApi";
import { logger } from "../../utilities/logger";


let getAllGameGroup = async () => {
    let response;
    

    try {
        response = await apiCallGet(AppConfig().reactBackendURL+"api/game/all", httpMethods.get, {});
        return response;    
    } catch (error) {
        logger.error(error);
        return false;
    }
}


let getGameDetail = async (gameCategoryId) => {
    let response;

    try {        

        response = await apiCallGet(AppConfig().reactBackendURL+"api/game/detail/"+gameCategoryId);
        return response;

    } catch (error) {
        logger.error(error);
        return false;
    }
}

let postOrder = async (data) => {
    let response;

    try {
        return response = await apiCallGet(AppConfig().reactBackendURL+"api/game/order/store", httpMethods.post, data);
    } catch (error) {
        logger.error(error)
        return false;
    }
}

let validateUserAccount = async (dataToCheck) => {
    let response;

    try {
        return response = await apiCallGet(AppConfig().reactBackendURL+"api/game/user/validate", httpMethods.post, dataToCheck);
    } catch (error) {
        logger.error(error)
        return false;
    }
}

export {
    getAllGameGroup,
    getGameDetail,
    postOrder,
    validateUserAccount
}