import React from "react";
import AppConfig from "../configs/AppConfig";
import useSetActivePage from "../hooks/useSetActivePage";
import "./css/paymentRedirect.css";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

let PaymentRedirect = () => {
  useSetActivePage("");
  let logoUrl = AppConfig().baseUrl + "assets/img/logo/unipin_full_logo.png";

  let {
    orderId,
    customerPhoneNumber,
    productName,
    productItem,
    totalAmount,
    paymentMethod,
    paymentStatus,
  } = useParams();

  return (
    <>
      {/* <HeaderOne/> */}
      <main>
        <section className="container redirect-container">
          <div className="row">
            <div className="col-12 text-center">
              <Link to="/">
                <img src={logoUrl} alt="" className="img-fluid payment-redirect-logo"/>
              </Link>
            </div>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-12 main-title">
              <h3>Your order payment and delivery detail</h3>
            </div>
          </div>

          <div className="row d-flex justify-content-center mt-4">
            <div className="col-6 order-receipt-label">Order Id :</div>
            <div className="col-6 order-receipt-value"> {orderId}</div>
          </div>

          <div className="row d-flex justify-content-center mt-4">
            <div className="col-6 order-receipt-label">
              Customer Phone Number :
            </div>
            <div className="col-6 order-receipt-value">
              {" "}
              {customerPhoneNumber}
            </div>
          </div>

          <div className="row d-flex justify-content-center mt-4">
            <div className="col-6 order-receipt-label">Product Name :</div>
            <div className="col-6 order-receipt-value"> {productName}</div>
          </div>

          <div className="row d-flex justify-content-center mt-4">
            <div className="col-6 order-receipt-label">Product's Item :</div>
            <div className="col-6 order-receipt-value"> {productItem}</div>
          </div>

          <div className="row d-flex justify-content-center mt-4">
            <div className="col-6 order-receipt-label">Total Amount :</div>
            <div className="col-6 order-receipt-value">
              {" "}
              {new Intl.NumberFormat("en-IN", {
                maximumSignificantDigits: 12,
              }).format(totalAmount)}{" "}
              MMK
            </div>
          </div>

          <div className="row d-flex justify-content-center mt-4">
            <div className="col-6 order-receipt-label">Payment Method :</div>
            <div className="col-6 order-receipt-value"> {paymentMethod}</div>
          </div>

          <div className="row d-flex justify-content-center mt-4">
            <div className="col-6 order-receipt-label"> Payment Status :</div>
            <div className="col-6 order-receipt-value"> {paymentStatus}</div>
          </div>

          <div className="row d-flex justify-content-center mt-4" >
            {paymentStatus === "success" ? (
              <div className="col-12 text-center">
                Your item is delivering and it will be notified with sms after
                delivered.
              </div>
            ) : (
              <div className="col-12 text-center">
                Your payment is not success and please try again.
              </div>
            )}
          </div>

          <div className="row d-flex justify-content-center mt-4">
            <div className="col-12 text-center">
              Please kindly make screenshopt this.
            </div>
          </div>

          <div className="row d-flex justify-content-center mt-4">
            <div className="col-12 text-center">
              <Link to="/" className="btn">
                {" "}
                Back to home
              </Link>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default PaymentRedirect;
