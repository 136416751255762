import {useEffect} from 'react'
import PropTypes from 'prop-types'
import GamePresenter from '../presenters/GamePresenter';

const useAllGroupFetch = (
    
) => {
    let { fetchAllGameGroup } = GamePresenter();
    useEffect(()=> {
         

        let fetchData = async () => {           
          await fetchAllGameGroup();            
        }
    
        fetchData();
    
      },[]);
}

useAllGroupFetch.propTypes = {
    
}

export default useAllGroupFetch