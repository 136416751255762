let appName;
let reactBackendUrl;
let logLevel;
let loadingImage;
let loadingImageWithoutTextUrl;
let baseUrl;
let userGameInfo = ["userid", "zoneid", "account"];
  

const AppConfig = () => {

  baseUrl = window.location.origin+"/";
  
  appName = process.env.REACT_APP_API_APP_NAME;
  reactBackendUrl = process.env.REACT_APP_API_BACKEND_URL;
  logLevel = process.env.REACT_APP_LOG_LEVEL;
  loadingImage = baseUrl + "assets/img/loading/4CXx.gif";
  loadingImageWithoutTextUrl = baseUrl + "assets/img/loading/loading-gif-background-transparent-12.gif";

  
  
  return {
    "appName" : appName,
    "reactBackendURL" : reactBackendUrl,
    "logLevel" : logLevel,
    "baseUrl" : baseUrl,
    "loadingImage" : loadingImage,
    "userGameInfo" : userGameInfo,
    "loadingImageWithoutTextUrl" : loadingImageWithoutTextUrl
  }


  
}

export default AppConfig;
