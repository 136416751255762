import { logger } from '../../utilities/logger';
import { apiCallGet, httpMethods } from './generic/GenericApi';


let getRandomCatFact = async () => {
    let response;
    try {
        response = await apiCallGet("https://catfact.ninja/fact", httpMethods.get, {});
        return response;    
    } catch (error) {
        logger.error(error);
        return false;
    }
    
}

export { getRandomCatFact };