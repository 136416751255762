import {getAllGameGroup, getGameDetail, postOrder, validateUserAccount} from "../dataAccess/apis/GameApis";
import { useDispatch } from "react-redux";
import {gameActions } from '../store/index';
import { logger } from "../utilities/logger";



const GamePresenter = () => {
    let dispatch = useDispatch();
    
    
    let fetchAllGameGroup  =  async () => {
        let data = null;
        let status = "unknown";
        
        try {
            let response = await getAllGameGroup(); 
            dispatch(gameActions.setAllGameGroup(response.data.data));


            status = "success"
            data = response.data;
            
        } catch (error) {
            status = "error"
        }

        return {status, data};
        

       
    };

    let fetchGameDetail = async (gameCategoryId) => {
        let data = null;
        let status = "unknown";

        try {
            let response = await getGameDetail(gameCategoryId);
            dispatch(gameActions.setGameDetail(response.data.data));
            
        } catch (error) {
            status = "error";
        }

        return {status, data}


    }

    let makeOrder = async (dataToPost) => {
        let data = null;
        let status = "unknown";

        try {
            let response = await postOrder(dataToPost);
            // logger.debug(response)
            
            if(response){
                status = "success";
                data = response.data.data;

            }
            
        } catch (error) {
            status = "error";
        }

        return {status, data}


    }


    let makeValidateUserGameInfo = async (dataToPost) => {
        let data = null;
        let status = "unknown";

        try {
            let response = await validateUserAccount(dataToPost);
            
            if(response){
                status = "success";
                data = response.data.data;

            }
            
        } catch (error) {
            status = "error";
        }

        return {status, data}

    }
  
    return {
        fetchAllGameGroup,
        fetchGameDetail,
        makeOrder,
        makeValidateUserGameInfo
    }

    


}

export default GamePresenter;