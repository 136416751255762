import React from 'react'
import PropTypes from 'prop-types'

const ZoneIdInput = ({
    onUpdateZoneId = () => {},
    isGamesLoaded = false,
    userGameInfos = [],
    zoneIdRequiredErr = false
}) => {

    let updateZoneId = (e) => {
        onUpdateZoneId(e);
    }
  return (
    <>
        {isGamesLoaded && userGameInfos.includes("zoneid") ? (
                      <div
                        className="col-6 col-xs-4 p-2"
                        style={{ border: "none" }}
                      >
                        <label
                          className="gameDetailFormlabel"
                          htmlFor="txtZoneId"
                        >
                          Zone Id
                        </label>
                        <input
                          className="form-control gameDetailFormInput"
                          id="txtZoneId"
                          onChange={(e) => updateZoneId(e)}
                          onBlur={(e) => updateZoneId(e)}
                        />
                        {zoneIdRequiredErr ? (
                          <span className="text-danger mt-2">
                            <small>
                              <i className="text-danger fa">&#xf00d;</i>&nbsp;
                              Please Enter Zone Id
                            </small>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
    </>
  )
}

ZoneIdInput.propTypes = {
    onUpdateZoneId : PropTypes.func,
    isGamesLoaded : PropTypes.bool,
    userGameInfos : PropTypes.array,
    zoneIdRequiredErr : PropTypes.bool
}

export default ZoneIdInput