import React from 'react'
import HeaderOne from '../components/header/HeaderOne';
import useSetActivePage from '../hooks/useSetActivePage';

function FAQs() {

  useSetActivePage("faqs");



  return (
    <>
       <HeaderOne/>
       <h1>This is FAQs page</h1>
    </>
    
  )
}

export default FAQs