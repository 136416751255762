import React from "react";
import LoadingImageWithoutText from "../utility/LoadingImageWithoutText";

const CheckValidateGameUserInfo = ({
    validateGamesUserInfoRegistered = () => {},
    validateUserInfoRegisterLoading = false,
    validateUserInfoRegisterAfter = false,
    validateUserInfoRegisterStatus = false,
    formTouched = false


}) => {
  return (
    <div className="col-12 d-flex justify-content-center align-middle">
      <button
        className="btnCheckUserInfo"
        onClick={(e) => {
          validateGamesUserInfoRegistered(e);
        }}
        disabled={validateUserInfoRegisterLoading}
      >
        {validateUserInfoRegisterLoading === false ? (
          "Check UserInfo"
        ) : (
          <LoadingImageWithoutText widthPercentage="20%" />
        )}
      </button>
      {validateUserInfoRegisterAfter === true ? (
        formTouched === true && validateUserInfoRegisterStatus === true ? (
          <div className="userValidResult">
            <i className="fa fa-check text-success" aria-hidden="true"></i>{" "}
            valid game user info
          </div>
        ) : (
          <div className="userValidResult">
            {" "}
            <i className="text-danger fa">&#xf00d;</i> invalid game user info
          </div>
        )
      ) : (
        ""
      )}
    </div>
  );
};

export default CheckValidateGameUserInfo;
