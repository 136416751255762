import React, {useState, useEffect} from "react";
import ShoppingItem from "../shop/ShoppingItem";
import { useSelector } from "react-redux";
import { logger } from "../../utilities/logger"
import AppConfig from "../../configs/AppConfig";
import LoadingImage from "../utility/LoadingImage";
import { Link } from "react-router-dom";

function ShopArea() {
  const gameReduxState = useSelector((state) => state.game);
  
let [isGamesLoaded, setIsGamesLoaded] = useState(false);
let [slicedGameGroups, setSlicedGameGroup]  = useState([]);
  

  let allGameGroups;
  

 

  useEffect(()=>{
    
    if(gameReduxState.allGameGroup != null){
      
      allGameGroups = gameReduxState.allGameGroup;
      
      let tempSlicedGameGroups = allGameGroups.slice(1, 7);  
      
      if(allGameGroups.length > 0){
        setSlicedGameGroup(tempSlicedGameGroups);
        setIsGamesLoaded(true);
      }

    }

    
  }, [gameReduxState])

  


  let popularGameList = (slicedGameGroups) => {
    return slicedGameGroups.map((value, index) => {
      return (
        <ShoppingItem
          key={index}
          imageUrl={value.image}
          title={value.game_product_category.name}
          gameCategoryId={value.game_product_category_id}
        />
      );
    })
  }

  
 
  

  return (
    <section className="shop-area pt-30 pb-60">
      <div className="container">
        <div className="row mb-3 d-flex align-items-center">
          <div className="col-md-9 col-12">
            <h2 className="theme-color">Popular Games</h2>
          </div>
          <div className="col-md-3 col-12 text-end">
            <Link to="/game-list" className=""> see more games [<span className="fa fa-plus"></span>]</Link>
          </div>
        </div>
        <div className="row">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempora
            quibusdam suscipit laborum quaerat hic magni? Ad at eum, animi
            pariatur in ducimus doloribus ratione qui est nesciunt expedita
            eligendi quaerat!
          </p>
        </div>
        <div className="row justify-content-center mt-2">
          { isGamesLoaded ?  popularGameList(slicedGameGroups) : <LoadingImage widthPercentage="20%" /> }
        </div>
      </div>
    </section>
  );
}

export default ShopArea;
