import React from 'react'
import PropTypes from 'prop-types'

const AccountInput = ({
    onUpdateAccount = () => {},
    isGamesLoaded = false,
    userGameInfos = [],
    accountRequiredErr = false
}) => {

    let updateAccount = (e) => {
        onUpdateAccount(e);
    }

  return (
    <>
        {isGamesLoaded && userGameInfos.includes("account") ? (
                      <div
                        className="col-6 col-xs-4 p-2"
                        style={{ border: "none" }}
                      >
                        <label
                          className="gameDetailFormlabel"
                          htmlFor="txtAccount"
                        >
                          Account
                        </label>
                        <input
                          className="form-control gameDetailFormInput"
                          id="txtAccount"
                          onChange={(e) => updateAccount(e)}
                          onBlur={(e) => updateAccount(e)}
                        />
                        {accountRequiredErr ? (
                          <span className="text-danger mt-2">
                            <small>
                              <i className="text-danger fa">&#xf00d;</i> &nbsp;
                              Please Enter account
                            </small>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
    </>
  )
}

AccountInput.propTypes = {
    onUpdateAccount : PropTypes.func,
    isGamesLoaded : PropTypes.bool,
    userGameInfos : PropTypes.array,
    accountRequiredErr : PropTypes.bool
}

export default AccountInput