import React from "react";
import { useEffect, useState } from "react";
import { gameActions } from "../store/index";
import { useDispatch } from "react-redux";
import HeaderOne from "../components/header/HeaderOne";
import Footer from "../components/Footer";
import "./css/gameDetail.css";
import GameItem from "../components/gameDetail/GameItem";
import Accordion from "react-bootstrap/Accordion";
import { useParams } from "react-router-dom";
import GamePresenter from "../presenters/GamePresenter";
import { useSelector } from "react-redux";
import { logger } from "../utilities/logger";
import ProductInfoGroup from "../components/gameDetail/ProductInfoGroup";
import AppConfig from "../configs/AppConfig";
import OrderNowBlk from "../components/gameDetail/OrderNowBlk";
import UserIdInput from "../components/gameDetail/UserIdInput";
import ZoneIdInput from "../components/gameDetail/ZoneIdInput";
import AccountInput from "../components/gameDetail/AccountInput";
import PhoneNumberInput from "../components/gameDetail/PhoneNumberInput";
import GameItemSelect from "../components/gameDetail/GameItemSelect";
import useGameDetailFetch from "../hooks/useGameDetailFetch";
import useSetActivePage from "../hooks/useSetActivePage";
import CheckValidateGameUserInfo from "../components/gameDetail/CheckValidateGameUserInfo";

const GameDetailPage = () => {
  let dispatch = useDispatch();
  let { gameCategoryId, gameTitle } = useParams();
  let { fetchGameDetail, makeOrder, makeValidateUserGameInfo } =
    GamePresenter();
  let [isGamesLoaded, setIsGamesLoaded] = useState(false);
  let [gameDetails, setGameDetails] = useState([]);
  let [displayItems, setDisplayItems] = useState([]);
  let [selectedItem, setSelectedItem] = useState({
    id: 0,
    name: "",
    telecomPrice: 0,
    walletPrice: 0,
  });
  let [userGameInfos, setUserGameInfos] = useState([]);
  let [userId, setUserId] = useState("");
  let [userIdRequiredErr, setUserIdRequiredErr] = useState(null);
  let [zoneId, setZoneId] = useState("");
  let [zoneIdRequiredErr, setZoneIdRequiredErr] = useState(null);
  let [account, setAccount] = useState("");
  let [accountRequiredErr, setAccountRequiredErr] = useState(null);
  let [phoneNumber, setPhoneNumber] = useState("");
  let [phoneNumberRequiredErr, setPhoneNumberRequiredErr] = useState(null);
  let [phoneNumberFormatErr, setPhoneNumberFormatErr] = useState(null);
  let [appSource, setAppSource] = useState("unknown");
  let [formTouched, setFormTouched] = useState(false);
  let [isOrderProcessing, setIsOrderProcessing] = useState(false);
  let [isOrderNowClicked, setIsOrderNowClicked] = useState(false);
  let [walletId, setWalletId] = useState(0);
  let [walletSelectErr, setWalletSelectErr] = useState(true);
  let [activeTelecom, setActiveTelecom] = useState("");
  let [validateUserInfoRegisterStatus, setValidateUserInfoRegisterStatus] =
    useState(false);
  let [validateUserInfoRegisterLoading, setValidateUserInfoRegisterLoading] =
    useState(false);
  let [validateUserInfoRegisterAfter, setValidateUserInfoRegisterAfter] =
    useState(false);

  useGameDetailFetch(fetchGameDetail, gameCategoryId);

  const gameReduxState = useSelector((state) => state.game);

  useEffect(() => {
    if (gameReduxState.gameDetail != null) {
      if (gameReduxState.gameDetail.length > 0) {
        setGameDetails(gameReduxState.gameDetail);
        setDisplayItems(gameReduxState.gameDetail[0].game_items);
        setIsGamesLoaded(true);
      }

      if (gameReduxState.displayItems.length > 0) {
        setDisplayItems(gameReduxState.displayItems);
        setIsGamesLoaded(true);
      }

      if (gameReduxState.selectedItem) {
        setSelectedItem(gameReduxState.selectedItem);
      }
      if (gameReduxState.appSource) {
        setAppSource(gameReduxState.appSource);
      }
    }
  }, [gameReduxState]);

  useEffect(() => {
    if (isGamesLoaded) {
      // logger.debug(gameDetails[0].game_user_profiles);
      
      let gameUserInfosTemp =
        gameReduxState.gameDetail[0].game_user_profiles.map((item, index) => {
          return item.field;
        });
      setUserGameInfos(gameUserInfosTemp);
      logger.debug(gameUserInfosTemp);
    }
  }, [gameReduxState, isGamesLoaded]);

  let baseUrl = window.location.origin + "/";

  let gameItemImgUrl = baseUrl + "assets/img/gameItems/game_item_1.png";

  useSetActivePage("game_list");

  let gameItemsDisplaying = (displayItems) => {
    return displayItems.map((item, index) => {
      return (
        <GameItem
          imageUrl={gameItemImgUrl}
          item={item}
          key={index}
          onValidate={validateForm}
        />
      );
    });
  };

  let gameInfo = {
    userid: userId,
    zoneid: zoneId,
    account: account,
  };

  for (let info in gameInfo) {
    if (!userGameInfos.includes(info)) {
      delete gameInfo[info];
    }
  }

  let onChangeUserId = async (e) => {
    setUserId(e.target.value);
    setFormTouched(true);
    userIdValidate();
    await validateGamesUserInfoRegistered(e)
  };

  let userIdValidate = () => {
    if (gameInfo.hasOwnProperty("userid") && userId === "") {
      setUserIdRequiredErr(true);
    } else {
      setUserIdRequiredErr(false);
    }
  };

  let onChangeZoneId = async (e) => {
    setZoneId(e.target.value);
    setFormTouched(true);
    zoneIdValidate();
    await validateGamesUserInfoRegistered(e)
  };

  let zoneIdValidate = () => {
    if (gameInfo.hasOwnProperty("zoneid") && zoneId === "") {
      setZoneIdRequiredErr(true);
    } else {
      setZoneIdRequiredErr(false);
    }
  };

  let onChangeAccount = async (e) => {
    setAccount(e.target.value);
    setFormTouched(true);
    accountValidate();
    await validateGamesUserInfoRegistered(e);
  };

  let accountValidate = () => {
    if (gameInfo.hasOwnProperty("account") && account === "") {
      setAccountRequiredErr(true);
    } else {
      setAccountRequiredErr(false);
    }
  };

  let onChangePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
    setFormTouched(true);
    phoneNumberValidate();
  };

  let phoneNumberValidate = () => {
    let mmPhoneNumberFormat = /^09\d{7,9}$/;

    if (phoneNumber.trim().length === 0) {
      setPhoneNumberRequiredErr(true);
    } else {
      setPhoneNumberRequiredErr(false);
      if(phoneNumber.trim().match(mmPhoneNumberFormat)){
        setPhoneNumberFormatErr(false)
      }
      else{
        setPhoneNumberFormatErr(true)
      }

    }
  };

  let onClickPaymentMethod = (intWalletId = 0) => {
    setWalletId(intWalletId);
  };

  useEffect(() => {
    if (walletId === 0) {
      setWalletSelectErr(true);
    } else {
      setWalletSelectErr(false);
    }
  }, [walletId]);

  let checkWalletSelectValidate = () => {
    logger.debug("walletId : " + walletId);
    logger.debug("wallet Select Error " + walletSelectErr);

    if (walletId === 0) {
      setWalletSelectErr(true);
    } else {
      setWalletSelectErr(false);
    }
  };

  let validateForm = () => {
    userIdValidate();
    zoneIdValidate();
    accountValidate();
    phoneNumberValidate();
    checkWalletSelectValidate();
  };

  useEffect(() => {
    logger.debug("is form touched " + formTouched);

    if (
      formTouched === true &&
      userIdRequiredErr === false &&
      zoneIdRequiredErr === false &&
      accountRequiredErr === false &&
      phoneNumberRequiredErr === false &&
      walletSelectErr === false &&
      validateUserInfoRegisterStatus === true &&
      phoneNumberFormatErr === false
    ) {
      logger.debug("set form valid true");
      dispatch(gameActions.setOrderFormValid(true));
    } else {
      logger.debug("set form valid false");
      dispatch(gameActions.setOrderFormValid(false));

      logger.debug(gameReduxState.isOrderFormValid);
    }
  }, [
    formTouched,
    userIdRequiredErr,
    zoneIdRequiredErr,
    accountRequiredErr,
    phoneNumberRequiredErr,
    walletSelectErr,
    validateUserInfoRegisterStatus,
    phoneNumberFormatErr
  ]);

  let resetForm = () => {
    setUserId("");
    setUserIdRequiredErr(null);
    setZoneId("");
    setZoneIdRequiredErr(null);
    setAccount("");
    setAccountRequiredErr(null);
    setPhoneNumber("");
    setPhoneNumberRequiredErr(null);
    setFormTouched(false);
    dispatch(gameActions.setOrderFormValid(false));
    setIsOrderNowClicked(false);
    document.getElementById("frmGameUserInfo").reset();
    setValidateUserInfoRegisterStatus(false);
    setValidateUserInfoRegisterLoading(false);
    setWalletSelectErr(false);
    setValidateUserInfoRegisterAfter(false);
    setPhoneNumberFormatErr(false);
  };

  let orderNow = async (e, paymentType) => {
    e.preventDefault();
    setIsOrderProcessing(true);
    setIsOrderNowClicked(true);
    let isOperator = paymentType === "telecom" ? true : false;

    validateForm();
    logger.debug("form init status " + formTouched.toString());

    let data = {
      items: [
        {
          game_id: selectedItem.id,
          game_info: gameInfo,
        },
      ],
      is_registered: false,
      is_operator: isOperator,
      customer_phone: phoneNumber,
      source: appSource,
    };
    logger.debug(data);
    logger.debug("form valid status : " + gameReduxState.isOrderFormValid);

    let response;

    if (gameReduxState.isOrderFormValid) {
      // Make Request
      response = await makeOrder(data);

      if (response) {
        logger.debug(response);
        let orderId = response.data.order_reference_id;
        let urlToRedirect =
          AppConfig().reactBackendURL +
          "payment/startpay/" +
          orderId +
          "/" +
          walletId;
        logger.debug(urlToRedirect);
        window.location = urlToRedirect;
      } else {
        logger.error(response);
        alert("error in order");
      }
      resetForm();
    }

    setTimeout(() => {
      setIsOrderProcessing(false);
    }, 1600);
  };

  let validateGamesUserInfoRegistered = async (e) => {
    setValidateUserInfoRegisterLoading(true);
    e.preventDefault();
    let userInfoToCheck = [];

    for (let info in gameInfo) {
      let objectToAdd = { field_name: info, value: gameInfo[info] };
      userInfoToCheck.push(objectToAdd);
    }
    let dataToPost = {
      code: gameReduxState.gameDetail[0].code,
      user_infos: userInfoToCheck,
    };
    logger.debug(dataToPost);

    let response = null;
    response = await makeValidateUserGameInfo(dataToPost);
    logger.debug(response.data.reason);

    if (response.data.reason === "Successful") {
      setValidateUserInfoRegisterStatus(true);
      // logger.debug("valid with : " + validateUserInfoRegisterStatus);
    } else {
      setValidateUserInfoRegisterStatus(false);
      // logger.debug("invalid with : " + validateUserInfoRegisterStatus);
    }

    setValidateUserInfoRegisterLoading(false);
    setValidateUserInfoRegisterAfter(true);
  };

  // logger.debug("is order processing : ");
  // logger.debug(isOrderProcessing);

  return (
    <>
      <HeaderOne />
      <main>
        {/* <Banner/> */}
        <section className="container">
          <div className="row">
            <ProductInfoGroup gameTitle={gameTitle} />

            <div
              className="col-lg-6 col-md-12 col-xs-12 col-sm-12 mt-2"
              style={{ minHeight: "50vh", border: "none" }}
            >
              <div className="container-fluid mt-4">
                <form action="#" id="frmGameUserInfo">
                  <div className="row">
                    <UserIdInput
                      onUpdateUserId={onChangeUserId}
                      isGamesLoaded={isGamesLoaded}
                      userGameInfos={userGameInfos}
                      userIdRequiredErr={userIdRequiredErr}
                    />

                    <ZoneIdInput
                      onUpdateZoneId={onChangeZoneId}
                      isGamesLoaded={isGamesLoaded}
                      userGameInfos={userGameInfos}
                      zoneIdRequiredErr={zoneIdRequiredErr}
                    />

                    <AccountInput
                      onUpdateAccount={onChangeAccount}
                      isGamesLoaded={isGamesLoaded}
                      userGameInfos={userGameInfos}
                      accountRequiredErr={accountRequiredErr}
                    />

                    <PhoneNumberInput
                      onUpdatePhoneNumber={onChangePhoneNumber}
                      phoneNumberRequiredErr={phoneNumberRequiredErr}
                      phoneNumberFormatErr={phoneNumberFormatErr}
                    />
                  </div>
                  <div className="row">
                    {/* <CheckValidateGameUserInfo
                      validateGamesUserInfoRegistered={
                        validateGamesUserInfoRegistered
                      }
                      validateUserInfoRegisterLoading={
                        validateUserInfoRegisterLoading
                      }
                      validateUserInfoRegisterAfter={
                        validateUserInfoRegisterAfter
                      }
                      validateUserInfoRegisterStatus={
                        validateUserInfoRegisterStatus
                      }
                      formTouched={formTouched}
                    /> */}
                    <div className="col-12">
                      <p className="gameDetailParagraph mt-2">
                        Please kindly provide game account user info to topup accordingly after payment is successfull.
                      </p>
                    </div>
                  </div>

                  <GameItemSelect
                    displayItems={displayItems}
                    isGamesLoaded={isGamesLoaded}
                    gameItemsDisplaying={gameItemsDisplaying}
                  />

                  <div className="row mt-4">
                    <h3>Select Payment Types</h3>
                  </div>

                  <div className="row">
                    <div className="col-12" >
                      <Accordion className="mt-4">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Telecom Operators</Accordion.Header>
                          <Accordion.Body style={{padding: "0", paddingTop: "20px"}} >
                            <div className="container-fluid">
                              <div className="row">
                                <div className="paymentMethod-grid">
                                  <div
                                    className={`paymentMethodBlk ${
                                      activeTelecom === "mpt"
                                        ? "paymentActive"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      onClickPaymentMethod(2);
                                      setActiveTelecom("mpt");
                                    }}
                                  >
                                    <img
                                      src={
                                        baseUrl +
                                        "assets/img/logo/telecom/mpt.png"
                                      }
                                      alt=""
                                    />
                                    <div className="paymentTitle">MPT </div>
                                  </div>
                                  <div
                                    className={`paymentMethodBlk ${
                                      activeTelecom === "atom"
                                        ? "paymentActive"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      onClickPaymentMethod(2);
                                      setActiveTelecom("atom");
                                    }}
                                  >
                                    <img
                                      src={
                                        baseUrl +
                                        "assets/img/logo/telecom/atom.png"
                                      }
                                      alt=""
                                    />
                                    <div className="paymentTitle">ATOM </div>
                                  </div>
                                  <div
                                    className={`paymentMethodBlk ${
                                      activeTelecom === "ooredoo"
                                        ? "paymentActive"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      onClickPaymentMethod(2);
                                      setActiveTelecom("ooredoo");
                                    }}
                                  >
                                    <img
                                      src={
                                        baseUrl +
                                        "assets/img/logo/telecom/ooredoo.png"
                                      }
                                      alt=""
                                    />
                                    <div className="paymentTitle">Ooredo </div>
                                  </div>
                                  <div
                                    className={`paymentMethodBlk ${
                                      activeTelecom === "mytel"
                                        ? "paymentActive"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      onClickPaymentMethod(2);
                                      setActiveTelecom("mytel");
                                    }}
                                  >
                                    <img
                                      src={
                                        baseUrl +
                                        "assets/img/logo/telecom/mytel.png"
                                      }
                                      alt=""
                                    />
                                    <div className="paymentTitle">MyTel </div>
                                  </div>
                                </div>
                                {formTouched === true &&
                                walletSelectErr === true ? (
                                  <>
                                    <div className="text-danger mt-2 text-center">
                                      <i className="text-danger fa">&#xf00d;</i>&nbsp;
                                      Please select one of the payment methods
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="row mt-4">
                                <div className="d-flex justify-content-center ">
                                  <p className="packageAndPrice">
                                    {" "}
                                    Selected Item : {selectedItem.name}{" "}
                                  </p>
                                  <p className="packageAndPrice">
                                    {" "}
                                    Amount :{" "}
                                    {new Intl.NumberFormat("en-IN", {
                                      maximumSignificantDigits: 12,
                                    }).format(selectedItem.telecomPrice)}{" "}
                                    MMK
                                  </p>
                                </div>
                              </div>
                              <div className="row mt-4 d-flex justify-content-center">
                                {validateUserInfoRegisterStatus ? (
                                  <div className="col-6 " >
                                    <OrderNowBlk
                                      telecomPrice={selectedItem.telecomPrice}
                                      isOrderProcessing={isOrderProcessing}
                                      isOrderNowClicked={isOrderNowClicked}
                                      isOrderFormValid={
                                        gameReduxState.isOrderFormValid
                                      }
                                      fnOrderNow={orderNow}
                                      paymentType="telecom"
                                    />
                                  </div>
                                ) : (
                                  <div className="col-12 ">
                                    <CheckValidateGameUserInfo
                                      validateGamesUserInfoRegistered={
                                        validateGamesUserInfoRegistered
                                      }
                                      validateUserInfoRegisterLoading={
                                        validateUserInfoRegisterLoading
                                      }
                                      validateUserInfoRegisterAfter={
                                        validateUserInfoRegisterAfter
                                      }
                                      validateUserInfoRegisterStatus={
                                        validateUserInfoRegisterStatus
                                      }
                                      formTouched={formTouched}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" className="mt-4 mb-4">
                          <Accordion.Header>Wallets</Accordion.Header>
                          <Accordion.Body style={{padding: "0", paddingTop: "20px"}}>
                            <div className="container-fluid">
                              <div className="row">
                                <div className="paymentMethod-grid">
                                  <div
                                    className={`paymentMethodBlk ${
                                      walletId === 1 ? "paymentActive" : ""
                                    }`}
                                    onClick={() => {
                                      onClickPaymentMethod(1);
                                    }}
                                  >
                                    <img
                                      src={
                                        baseUrl +
                                        "assets/img/logo/wallet/kbz_pay.png"
                                      }
                                      alt=""
                                    />
                                    <div className="paymentTitle">KPay </div>
                                  </div>
                                  <div
                                    className={`paymentMethodBlk ${
                                      walletId === 6 ? "paymentActive" : ""
                                    }`}
                                    onClick={() => {
                                      onClickPaymentMethod(6);
                                    }}
                                  >
                                    <img
                                      src={
                                        baseUrl +
                                        "assets/img/logo/wallet/ok_dollar.png"
                                      }
                                      alt=""
                                    />
                                    <div className="paymentTitle">
                                      OK Dollar
                                    </div>
                                  </div>
                                  <div
                                    className={`paymentMethodBlk ${
                                      walletId === 7 ? "paymentActive" : ""
                                    }`}
                                    onClick={() => {
                                      onClickPaymentMethod(7);
                                    }}
                                  >
                                    <img
                                      src={
                                        baseUrl +
                                        "assets/img/logo/wallet/sai_sai_pay.png"
                                      }
                                      alt=""
                                    />
                                    <div className="paymentTitle">
                                      Sai Sai Pay
                                    </div>
                                  </div>
                                  <div
                                    className={`paymentMethodBlk ${
                                      walletId === 8 ? "paymentActive" : ""
                                    }`}
                                    onClick={() => {
                                      onClickPaymentMethod(8);
                                    }}
                                  >
                                    <img
                                      src={
                                        baseUrl +
                                        "assets/img/logo/wallet/one_pay.png"
                                      }
                                      alt=""
                                    />
                                    <div className="paymentTitle">One Pay</div>
                                  </div>
                                  <div
                                    className={`paymentMethodBlk ${
                                      walletId === 9 ? "paymentActive" : ""
                                    }`}
                                    onClick={() => {
                                      onClickPaymentMethod(9);
                                    }}
                                  >
                                    <img
                                      src={
                                        baseUrl +
                                        "assets/img/logo/wallet/aya_pay.png"
                                      }
                                      alt=""
                                    />
                                    <div className="paymentTitle">Aya Pay</div>
                                  </div>
                                  <div
                                    className={`paymentMethodBlk ${
                                      walletId === 10 ? "paymentActive" : ""
                                    }`}
                                    onClick={() => {
                                      onClickPaymentMethod(10);
                                    }}
                                  >
                                    <img
                                      src={
                                        baseUrl +
                                        "assets/img/logo/wallet/master_visa_pay.png"
                                      }
                                      alt=""
                                    />
                                    <div className="paymentTitle">
                                      Visa Master
                                    </div>
                                  </div>
                                  <div
                                    className={`paymentMethodBlk ${
                                      walletId === 11 ? "paymentActive" : ""
                                    }`}
                                    onClick={() => {
                                      onClickPaymentMethod(11);
                                    }}
                                  >
                                    <img
                                      src={
                                        baseUrl +
                                        "assets/img/logo/wallet/wave_pay.png"
                                      }
                                      alt=""
                                    />
                                    <div className="paymentTitle">
                                      Wave Money
                                    </div>
                                  </div>
                                  <div
                                    className={`paymentMethodBlk ${
                                      walletId === 12 ? "paymentActive" : ""
                                    }`}
                                    onClick={() => {
                                      onClickPaymentMethod(12);
                                    }}
                                  >
                                    <img
                                      src={
                                        baseUrl +
                                        "assets/img/logo/wallet/uab_pay.png"
                                      }
                                      alt=""
                                    />
                                    <div className="paymentTitle">UAB Pay</div>
                                  </div>
                                  <div
                                    className={`paymentMethodBlk ${
                                      walletId === 13 ? "paymentActive" : ""
                                    }`}
                                    onClick={() => {
                                      onClickPaymentMethod(13);
                                    }}
                                  >
                                    <img
                                      src={
                                        baseUrl +
                                        "assets/img/logo/wallet/mpu_pay.png"
                                      }
                                      alt=""
                                    />
                                    <div className="paymentTitle">MPU Pay</div>
                                  </div>
                                  <div
                                    className={`paymentMethodBlk ${
                                      walletId === 15 ? "paymentActive" : ""
                                    }`}
                                    onClick={() => {
                                      onClickPaymentMethod(15);
                                    }}
                                  >
                                    <img
                                      src={
                                        baseUrl +
                                        "assets/img/logo/wallet/cb_pay.png"
                                      }
                                      alt=""
                                    />
                                    <div className="paymentTitle">CB Pay</div>
                                  </div>
                                  <div
                                    className={`paymentMethodBlk ${
                                      walletId === 16 ? "paymentActive" : ""
                                    }`}
                                    onClick={() => {
                                      onClickPaymentMethod(16);
                                    }}
                                  >
                                    <img
                                      src={
                                        baseUrl +
                                        "assets/img/logo/wallet/mypite_san.png"
                                      }
                                      alt=""
                                    />
                                    <div className="paymentTitle">Mpitesan</div>
                                  </div>
                                </div>
                                {formTouched === true &&
                                walletSelectErr === true ? (
                                  <>
                                    <div className="text-danger mt-2 text-center">
                                      <i className="text-danger fa">&#xf00d;</i>&nbsp;
                                      Please select one of the payment methods
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="row mt-4">
                                <div className="flex justify-content-center text-center">
                                  <p className="packageAndPrice">
                                    {" "}
                                    Selected Item : {selectedItem.name}{" "}
                                  </p>
                                  <p className="packageAndPrice">
                                    {" "}
                                    Amount :{" "}
                                    {new Intl.NumberFormat("en-IN", {
                                      maximumSignificantDigits: 12,
                                    }).format(selectedItem.walletPrice)}{" "}
                                    MMK
                                  </p>
                                </div>
                              </div>
                              <div className="row mt-4 d-flex justify-content-center">
                                {validateUserInfoRegisterStatus ? (
                                  <div className="col-6">
                                    <OrderNowBlk
                                      telecomPrice={selectedItem.telecomPrice}
                                      isOrderProcessing={isOrderProcessing}
                                      isOrderNowClicked={isOrderNowClicked}
                                      isOrderFormValid={
                                        gameReduxState.isOrderFormValid
                                      }
                                      fnOrderNow={orderNow}
                                      paymentType="wallet"
                                    />
                                  </div>
                                ) : (
                                  <div className="col-12">
                                    <CheckValidateGameUserInfo
                                      validateGamesUserInfoRegistered={
                                        validateGamesUserInfoRegistered
                                      }
                                      validateUserInfoRegisterLoading={
                                        validateUserInfoRegisterLoading
                                      }
                                      validateUserInfoRegisterAfter={
                                        validateUserInfoRegisterAfter
                                      }
                                      validateUserInfoRegisterStatus={
                                        validateUserInfoRegisterStatus
                                      }
                                      formTouched={formTouched}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default GameDetailPage;
