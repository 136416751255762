import React from 'react';
import LandingPage from "../pages/landing_page";
import {  Routes, Route } from "react-router-dom";
import AboutUsPage from '../pages/about_us_page';
import GameLisPage from '../pages/game_list_page';
import GameDetailPage from '../pages/game_detail_page';
import ContactUsPage from '../pages/contact_us_page';
import UserProfilePage from '../pages/user_profile_page';
import BuyHistoryPage from '../pages/buy_history_page';
import FAQs from '../pages/faqs';
import PaymentRedirect from '../pages/payment_redirect';
import NotFound404Page from '../pages/not_found_404_page';

const AppRouter = () => {
  
  

  return (
    <>
        
      
      
      <Routes>
          <Route index path="/" element={<LandingPage />} />
            
          <Route index path="/source/:sourceName" element={<LandingPage />} />
          
          

          <Route  path="/about-us" element={<AboutUsPage />} />
          <Route  path="/game-list" element={<GameLisPage />} />
          <Route  path="/game-detail/:gameCategoryId/:gameTitle" element={<GameDetailPage />} />
          <Route  path="/contact-us" element={<ContactUsPage />} />
          <Route  path="/user-profile" element={<UserProfilePage />} />
          <Route  path="/buy-history" element={<BuyHistoryPage />} />
          <Route  path="/faqs" element={<FAQs />} />
          <Route path="/payment-redirect/:orderId/:customerPhoneNumber/:productName/:productItem/:totalAmount/:paymentMethod/:paymentStatus" element={<PaymentRedirect />} /> 
          <Route path="404-not-found" element={<NotFound404Page />}/>
          <Route path="*" element={<NotFound404Page />}/>
          
      </Routes>
      
    </>
  )
}

export default AppRouter