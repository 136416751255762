import { createSlice} from "@reduxjs/toolkit";
// import { logger } from "../../Utilities/Logger";
// import { getRandomCatFact } from "../../dataAccess/apis/CatApis";

const initialState = {
    randomCatFact : {
     loading : false,
     fact : null
    }
}

const catSlice = createSlice({
  name: "catFact",
  initialState: initialState,  
  reducers: {
    setRandomCatFact: (state, action) => {
      state.randomCatFact.loading = true;

     
      state.randomCatFact.fact = action.payload;
      
      

      state.randomCatFact.loading = false;
    }
    
  },
  
});

export default catSlice;
