import React from 'react'
import PropTypes from 'prop-types'

const TomCat = ({ var1 }) => {
  return (
    <div>TomCat count {var1}</div>
  )
}

TomCat.propTypes = {
    var1 : PropTypes.number.isRequired
}

export default TomCat