import { configureStore} from '@reduxjs/toolkit';
import navActiveSlice from './slice/navActiveSlice';
import catSlice from './slice/catSlice';
import gameSlice from './slice/gameSlice'



const store = configureStore({
    reducer: {        
        navActive : navActiveSlice.reducer,
        catFact : catSlice.reducer,
        game : gameSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
    
});

export const navActiveActions  = navActiveSlice.actions;
export const catFactActions = catSlice.actions;
export const gameActions = gameSlice.actions;

export default store;

