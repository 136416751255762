import React from 'react'
import HeaderOne from '../components/header/HeaderOne';
import useSetActivePage from '../hooks/useSetActivePage';

const ContactUsPage = () => {

 

  useSetActivePage("contact_us");

  return (
    <>
      <HeaderOne/>
      <h1>This is contact us page!</h1>
    </>
  )
}

export default ContactUsPage