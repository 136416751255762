import React from 'react';
import Footer from '../components/Footer'
import HeaderOne from '../components/header/HeaderOne'
import AreaBg from '../components/headerone/AreaBg'
import Banner from '../components/headerone/Banner'
import { logger } from '../utilities/logger';
import useSetActivePage from '../hooks/useSetActivePage';
import useAllGroupFetch from '../hooks/useAllGroupFetch';


const LandingPage = () => {

  
    

  useSetActivePage("home");

  useAllGroupFetch();

  return (
    <>
        <HeaderOne/>
            <main>
                {/* <Banner/> */}
                <AreaBg/>
            </main>
        <Footer/>
    </>
    
  )
}

export default LandingPage;