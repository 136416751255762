import React from 'react'
import PropTypes from 'prop-types'

let UserIdInput = ({
    onUpdateUserId = () => {},
    isGamesLoaded = false,
    userGameInfos = [],
    userIdRequiredErr = false
}) => {
    
    let updateUserId = (e) => {
        onUpdateUserId(e);
    }

  return (
    

    <>
        {isGamesLoaded && userGameInfos.includes("userid") ? (
                      <div
                        className="col-6 col-xs-4 p-2"
                        style={{
                          border: "none",
                          minHeight: "80px",
                          clear: "both",
                        }}
                      >
                        <label
                          className="gameDetailFormlabel"
                          htmlFor="txtUserId"
                        >
                          User Id
                        </label>
                        <input
                          className="form-control gameDetailFormInput"
                          id="txtUserId"
                          onChange={(e) => updateUserId(e)}
                          onBlur={(e) => updateUserId(e)}
                        />
                        {userIdRequiredErr ? (
                          <span className="text-danger mt-2">
                            <small> 
                              <i className="text-danger fa">&#xf00d;</i> &nbsp; Please Eenter User Id
                            </small>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )} 
    </>
  )
}

UserIdInput.propTypes = {
    onUpdateUserId : PropTypes.func,
    isGamesLoaded : PropTypes.bool,
    userGameInfos : PropTypes.array,
    userIdRequiredErr : PropTypes.bool
}

export default UserIdInput
