import React from 'react';
import { useEffect } from 'react';
import {navActiveActions } from '../store/index';
import { useDispatch } from "react-redux";

function UserProfilePage() {

  let dispatch = useDispatch();
  
  useEffect(()=> {
    dispatch(navActiveActions.setActivePage('user_profile'));
    
  }, []);

  return (
   <h1> This is User Profile Page</h1>
  )
}

export default UserProfilePage