import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { gameActions } from "../../store";
import { useSelector } from "react-redux";
import { logger } from "../../utilities/logger";

function GameItem({
    item,
    imageUrl,
    onValidate
    
}) {
  let dispatch = useDispatch();
  const gameReduxState = useSelector((state) => state.game);  
  let [selectedItem, setSelectedItem] = useState({});

  useEffect(()=>{

    if(gameReduxState.selectedItem){
      setSelectedItem(gameReduxState.selectedItem);
    }

  }, [gameReduxState] )



  let setDisplayItemPrice = (item) => {

    let itemPrices = {
        id : item.id,
        name : item.name,
        telecomPrice : item.operator_price,
        walletPrice : item.wallet_price
    }
    logger.debug(itemPrices)
    onValidate();
    
    dispatch(gameActions.setGameItemPrices(itemPrices));
  }

  return (
    
    <div className={`gameItem ${selectedItem.id === item.id ? 'gameItemActive' : ''} `} onClick={() => {  setDisplayItemPrice(item)} } >
      <img src={imageUrl} alt="game item"/>
      <p>{item.name}</p>
    </div>
    
  );
}

GameItem.propTypes = {
    imageUrl : PropTypes.string,
    item : PropTypes.object.isRequired,
};

export default GameItem;
