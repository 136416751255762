import React from "react";
import AppRouter from "./routes/app_router";


function App() {
 

  
  return (
    <>
      
      <AppRouter></AppRouter>      
      
      
      
      



      
    </>
  );
}

export default App;
