import React from 'react'

const AppFeatureItem = ({
    imgUrl = "",
    text1 = "",
    text2 = ""
}) => {
  return (
    <>  
        <div className="media  d-flex flex-row justify-content-start mt-4">
            
            <img
                    src={imgUrl}
                    alt=""
                    className={`align-self-start img-fluid`}
                    style={{ marginRight: "8px"}}
                  />
            
            <div className="media-body d-flex flex-column" style={{marginLeft: "0px"}}>
                  <h4 className="text-start">{text1}</h4>
                  <h6 className="text-start">{text2}</h6>
                </div>
              
            </div>
          
    </>
  )
}

export default AppFeatureItem