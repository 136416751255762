import React from 'react'
import TomCat from '../components/TomCat'
import HeaderOne from '../components/header/HeaderOne';
import useSetActivePage from '../hooks/useSetActivePage';
import useCatFactFetch from '../hooks/useCatFactFetch';


const AboutUsPage =  () => {    
    
    useSetActivePage("about_us"); 

    let [sampleResp, textLoading] = useCatFactFetch();

    
    
    

  return (
    <>
       <HeaderOne/>
        <h1> This is about us page</h1>
        <TomCat var1={1} />

        <p>
            { textLoading ? "loading..." : sampleResp.fact}
        </p>
        
    </>
  )
}

export default AboutUsPage