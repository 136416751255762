import axios from "axios";
import {logger} from '../../../utilities/logger';

let httpMethods = {
    "get" : "GET",
    "post" : "POST",
    "put" : "PUT",
    "delete" : "DELETE"
};


let apiCallGet = async ( url = "", method = "GET", data = {}) => {
    
    let response;
    

    try{
        response = await axios.request({
            url : url,
            method : method,
            data : data,
            headers : { 
                'Access-Control-Allow-Origin' : '*'
            }
        });
        

    }catch(err){
        logger.error("#api_error " + err);
        return false;
    }

    return response;
}

export {apiCallGet, httpMethods};

