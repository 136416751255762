import React from "react";
import GamerArea from "../headertwo/GamerArea";
import ShopArea from "../headertwo/ShopArea";
import Newsletter from "../Newsletter";
import Gallery from "./Gallery";
import GameListByPlatform from '../landingPage/GameListByPlatform'
import AppFeature from "../landingPage/AppFeature";


function AreaBg() {
  return (
    <>
      {/* <Tournament/> */}
      <Gallery />
      <AppFeature />     

      {/* <Team/> */}
      {/* <Live/> */}
      <ShopArea />
      {/* <GamerArea /> */}
      
      <GameListByPlatform />  

      <Newsletter/>
    </>
  );
}

export default AreaBg;
