import React from "react";
import PropTypes from "prop-types";
import LoadingImage from "../utility/LoadingImage";

const GameItemSelect = ({
    isGamesLoaded = false,
    displayItems = [],
    gameItemsDisplaying = () => {},

}) => {
  return (
    <>
      <div className="row">
        <h3>Select Item</h3>
      </div>

      <div className="row">
        <div className="col-12 ">
          <div className="gameItemContainer pt-2 pb-4">
            {isGamesLoaded ? (
              gameItemsDisplaying(displayItems)
            ) : (
              <LoadingImage widthPercentage="20%" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

GameItemSelect.propTypes = {
    displayItems : PropTypes.array,
    isGamesLoaded : PropTypes.bool,
    gameItemsDisplaying : PropTypes.func,
};

export default GameItemSelect;
