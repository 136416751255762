import  {useEffect} from 'react'
import PropTypes from 'prop-types'

const useGameDetailFetch = (
    fetchGameDetail = () => {},
    gameCategoryId = ""
) => {


    useEffect(() => {
        let fetchGameDetailData = async () => {
          await fetchGameDetail(gameCategoryId);
        };
    
        fetchGameDetailData();
      }, []);
}

useGameDetailFetch.propTypes = {
    fetchGameDetail : PropTypes.func.isRequired,
    gameCategoryId : PropTypes.string.isRequired,
}

export default useGameDetailFetch