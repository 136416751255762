import React from "react";
import PropTypes from "prop-types";
import LoadingImageWithoutText from "../utility/LoadingImageWithoutText";

function OrderNowBlk({
  telecomPrice = 0,
  isOrderProcessing = false,
  isOrderNowClicked = false,
  isOrderFormValid = false,
  fnOrderNow = (e, vari) => {},
  paymentType = "telecom",
}) {
  let makeOrder = (e) => {
    fnOrderNow(e, paymentType);
  };

  return (
    <>
      {telecomPrice > 0 ? (
        <div className="col-12 flex-column">
          <button
            className=" btn mb-4 btn-order-now"
            
            onClick={(e) => {
              makeOrder(e);
            }}
            disabled={isOrderProcessing}
          >
            {isOrderProcessing ? (
              <LoadingImageWithoutText widthPercentage="20%" />
            ) : (
              "Order Now"
            )}
          </button>

          {isOrderFormValid == false && isOrderNowClicked ? (
            <div
              className="text-danger  mt-2 col-12"
              style={{ marginLeft: "12px" }}
            >
              <ul>
                <li>
                  <small>
                    Please fill required fields (or)
                  </small>
                </li>
                <li>
                  <small>
                    select a payment method accordingly
                  </small>
                </li>
                
              </ul>
            </div>
          ) : (
            ""
          )}

          
        </div>
      ) : (
        ""
      )}
    </>
  );
}

OrderNowBlk.propTypes = {
  telecomPrice: PropTypes.number,
  isOrderProcessing: PropTypes.bool,
  isOrderNowClicked: PropTypes.bool,
  isOrderFormValid: PropTypes.bool,
  fnOrderNow: PropTypes.func,
  paymentType: PropTypes.string,
};

export default OrderNowBlk;
