import React from "react";
import AppFeatureItem from "./AppFeatureItem";
import "./AppFeature.css";
// import PropTypes from "prop-types";

function AppFeature(props) {
  return (
    <>
      <section className="app-feature container">
        <div className="row app-features-block">
          
          <AppFeatureItem 
            imgUrl="assets/img/app_features/support_payments.png"
            text1="Support Most"
            text2="of Payments"
          />

          

          <AppFeatureItem 
            imgUrl="assets/img/app_features/any_time.png"
            text1="Anytime"
            text2="can make order"
          />

          

          <AppFeatureItem 
            imgUrl="assets/img/app_features/biggest_store.png"
            text1="Anytime"
            text2="biggest stores"
          />

          

          <AppFeatureItem 
            imgUrl="assets/img/app_features/secure_data.png"
            text1="More Secure"
            text2="for your data"
          />
        </div>
      </section>
    </>
  );
}

// AppFeature.propTypes = {};

export default AppFeature;
