import React from 'react';
import { Link } from 'react-router-dom';
import './css/not_found_404_page.css'

const NotFound404Page = () => {
  return (
    <div> 
      <main>
        <section className="container not-found-container">
          <h1 className="main-title"> 404 Not Found</h1>
          <div className="row">
            <div className="col-12 text-center mt-4" style={{border : "none"}}>
              <img src="./assets/img/error/404.png" alt="" className="img-fluid not-found-img" style={{border : "none"}} />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-8 col-sm-6 text-center mt-4">
              <p className="not-found-text">
                Your searched URI or requested resource could not not be found in our website. You can redirect to home page with below button.
              </p>
              
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12 col-sm-6 text-center mt-4">
                <Link to="/" className='btn back-to-home'> Back to home</Link>
            </div>
          </div>

        </section>
      </main>
    </div>
  )
}

export default NotFound404Page