import {useEffect} from 'react'
import PropTypes from 'prop-types'
import {navActiveActions } from '../store/index';
import { useDispatch } from "react-redux";

const useSetActivePage = (
    
    pageTitle = "",
) => {
    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(navActiveActions.setActivePage(pageTitle));
      }, []);
}

useSetActivePage.propTypes = {
   pageTitle: PropTypes.string.isRequired
}

export default useSetActivePage