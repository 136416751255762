import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ShoppingItem from "../shop/ShoppingItem";
import { useSelector } from "react-redux";
import { logger } from "../../utilities/logger";
import LoadingImage from "../utility/LoadingImage";

function GameListByPlatform(props) {
  const gameReduxState = useSelector((state) => state.game);  
  let [isGamesLoaded, setIsGamesLoaded] = useState(false);
  let [allGameGroups, setAllGameGroups] = useState([]); 

  

  useEffect(()=> {
    if(gameReduxState.allGameGroup != null){

      if(gameReduxState.allGameGroup.length > 0){
        setAllGameGroups(gameReduxState.allGameGroup);
        setIsGamesLoaded(true);
      }
      
    }
  }, [gameReduxState])


  let mobileGameList = (allGameGroups) => {
    let mobileGameGroup = allGameGroups;
    return mobileGameGroup.map((value, index) => {
      
      return (
        
          <ShoppingItem
            key={index}
            imageUrl={value.image}
            gameCategoryId={value.game_product_category_id}
            title={value.game_product_category.name}
            
          />
          
        
      );
    });
  }

  let pcGameList = (allGameGroups) => {
    let pcGameGroup = allGameGroups;
    return pcGameGroup.map((value, index) => {
      
      return (
        
          <ShoppingItem
            key={index}
            imageUrl={value.image}
            gameCategoryId={value.game_product_category_id}
            title={value.game_product_category.name}
            
          />
          
        
      );
    });
  }

  let voucherList = (allGameGroups) => {
    let voucherList = allGameGroups;
    return voucherList.map((value, index) => {
      
      return (
        
          <ShoppingItem
            key={index}
            imageUrl={value.image}
            gameCategoryId={value.game_product_category_id}
            title={value.game_product_category.name}
            
          />
          
        
      );
    });
  }
  

  return (
    <>
      <section className="game-list-by-platform container">
        <div className="row mb-3 d-flex align-items-center">
          <div className="col-10">
            <h2 className="theme-color">Games by Platforms</h2>
          </div>
        </div>
        <Tabs
          defaultActiveKey="mobile"
          transition={false}
          id="noanim-tab-example"
          className="mb-3"
        >
          <Tab eventKey="mobile" title="mobile">
            <div className="row justify-content-center mt-4">
              { isGamesLoaded ? mobileGameList(allGameGroups) : <LoadingImage widthPercentage="20%"/>}
            </div>
          </Tab>
          <Tab eventKey="pcGame" title="pc game">
            <div className="row justify-content-center mt-4">
             
            { isGamesLoaded ? pcGameList(allGameGroups) : <LoadingImage widthPercentage="20%"/>}
            </div>
          </Tab>
          <Tab eventKey="voucher" title="voucher">
            <div className="row justify-content-center mt-4">
              
              { isGamesLoaded ? voucherList(allGameGroups) : <LoadingImage widthPercentage="20%"/>}
            </div>
          </Tab>
        </Tabs>
      </section>
    </>
  );
}

GameListByPlatform.propTypes = {};

export default GameListByPlatform;
