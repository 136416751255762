import React from "react";
import PropTypes from "prop-types";

const PhoneNumberInput = ({
    onUpdatePhoneNumber = () => {},    
    phoneNumberRequiredErr = false,
    phoneNumberFormatErr = false
}) => {

    let updatePhoneNumber = (e) => {
        onUpdatePhoneNumber(e);
    }
  return (
    <>
      <div className="col-6 col-xs-4 p-2" style={{ border: "none" }}>
        <label className="gameDetailFormlabel" htmlFor="txtPhoneNumber">
          Phone Number
        </label>

        <input
          type="tel"         
          className="form-control gameDetailFormInput"
          id="txtPhoneNumber"
          onChange={(e) => updatePhoneNumber(e)}
          onBlur={(e) => updatePhoneNumber(e)}
          
        />
        {phoneNumberRequiredErr ? (
          <span className="text-danger mt-2">
            <small>
              <i className="text-danger fa">&#xf00d;</i> &nbsp;
              Please Enter Phone Number
            </small>
          </span>
        ) : (
          ""
        )}
        {phoneNumberFormatErr ? (
          <span className="text-danger mt-2">
            <small>
              <i className="text-danger fa">&#xf00d;</i> &nbsp;
              Please Enter Phone Number with 09 format e.g. 09793432709
            </small>
          </span>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

PhoneNumberInput.propTypes = {
    onUpdatePhoneNumber : PropTypes.func,    
    phoneNumberRequiredErr : PropTypes.bool
};

export default PhoneNumberInput;
